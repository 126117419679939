// getTabLabel.ts
import i18n from "../../../i18n";

export const getTabLabel = (tableType: string): string => {
  switch (tableType) {
    case 'users_summary':
      return i18n.t('Users Summary'); 
      // or i18n.t('tabLabels.usersSummary') if you keep translations in nested keys

    case 'securispots_list':
      return i18n.t('Securispots List');

    case 'location_summary':
      return i18n.t('Location Summary');

    default:
      return i18n.t('Table');
  }
};
