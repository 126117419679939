import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import TreeComponent from './Treecomponent';
import TableComponent from './tablecomponent';

import styles from './styles/GlobaleView.module.css';
import stylesTabelSection from './styles/TabelSection.module.css';
import stylesTreeSection from './styles/TreeSection.module.css';

import { NodeDetails, Node } from './Treeutils/helpers/types';
import { initialData } from './Treeutils/helpers/treeData';
import Spinner from './Treeutils/components/Spinner';
import { getTabLabel } from './TableTreeinteractionutils/helpers/getTabLabel';
import { handleNodeDoubleClickHelper } from './TableTreeinteractionutils/helpers/tableInteractionHelpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faWifi, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
const GlobalViewComponent: React.FC = () => {
  const [data, setData] = useState<Node[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedNode, setSelectedNode] = useState<NodeDetails | null>(null);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [tableConfig, setTableConfig] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await initialData();
        setData(result);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleNodeDoubleClick = (details: NodeDetails) => {
    handleNodeDoubleClickHelper(details, setSelectedNode, setTableConfig, setCurrentTab);
  };

  if (loading) return <Spinner />;

  const currentTable = tableConfig.find((table) => table.tableType === currentTab) || tableConfig[0];

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.container}>
        {/* Tree Section */}
        <div className={stylesTreeSection.treeSection}>
          <div className={stylesTreeSection.searchContainer}>
            <input
              type="text"
              className={stylesTreeSection.searchInput}
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                className={stylesTreeSection.clearButton}
                onClick={() => setSearchTerm('')}
              >
                Clear
              </button>
            )}
          </div>

          <TreeComponent
          data={data}
          setData={setData}
          searchTerm={searchTerm}
          onNodeDoubleClick={handleNodeDoubleClick}
          setTableConfig={setTableConfig} // Pass the setTableConfig function
          currentTab={"bureau_summary"} // Pass the bureau_summary string we pass just bureau_summary as currentTab because we just use it in the dragg from the table to the tree where it forcely it's open as currentTab :)
        />

        </div>

        {/* Table Section */}
        <div className={stylesTabelSection.tableSection}>
          {selectedNode && tableConfig.length > 1 && (
            <div className={stylesTabelSection.headerContainer}>
              {/* Tab buttons */}
              <div className={stylesTabelSection.tabsContainer}>
                {tableConfig.map((table) => (
                  <button
                    key={table.tableType}
                    className={`${stylesTabelSection.tabButton} ${
                      currentTab === table.tableType ? stylesTabelSection.activeTab : ''
                    }`}
                    onClick={() => setCurrentTab(table.tableType)}
                  >
                    <span className={stylesTabelSection.tabIcon}>
                      {table.tableType === 'users_summary' && <FontAwesomeIcon icon={faUser as IconProp} />}
                      {table.tableType === 'securispots_list' && <FontAwesomeIcon icon={faWifi as IconProp} />}
                      {table.tableType === 'location_summary' && (
                        <FontAwesomeIcon icon={faLocationDot as IconProp} />
                      )}
                    </span>
                    {getTabLabel(table.tableType)}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Table Content */}
          <TableComponent
  columns={currentTable?.columns || []}
  data={currentTable?.data || []}
  setData={(updateFn) => {
    setTableConfig((prevConfig) =>
      prevConfig.map((table) =>
        table.tableType === currentTab
          ? { ...table, data: typeof updateFn === 'function' ? updateFn(table.data) : updateFn }
          : table
      )
    );
  }}
/>

        </div>
      </div>
    </DndProvider>
  );
};

export default GlobalViewComponent;
