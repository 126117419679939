import React, { useState, useRef } from "react";
import { DateTime } from "luxon";
import i18n from "../i18n";
import classes from "./AlarmsList.module.css";
import { useAlarms } from "./AlarmsListUtils/useAlarms";
import { AlarmsTable, AlarmsTableHandle } from "./AlarmsListcomponents/AlarmsTable";
import LoaderComponent from "./AlarmsListcomponents/LoaderComponent";
import DatePickerComponent from "./AlarmsListcomponents/DatePickerComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadAlarmsCSV } from "./AlarmsListUtils/downloadHelpers";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IProps {
  numberDays?: number;
  height?: string;
  type?: number;
  serial?: string;
  title?: string;
}

export const AlarmsList = ({
  numberDays = 1,
  height = "61vh",
  type,
  serial,
  title,
}: IProps) => {
  const todayMidnightTimestamp = new Date(
    new Date().setHours(0, 0, 0, 0)
  ).getTime();
  const [start, setStart] = useState(
    todayMidnightTimestamp - 1000 * 60 * 60 * 24 * (numberDays - 1)
  );
  const [end, setEnd] = useState(Date.now());
  const { alarms, isLoading } = useAlarms(start, end, serial, type);

  const alarmsTableRef = useRef<AlarmsTableHandle>(null);
  const [hasFilters, setHasFilters] = useState(false);

  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      setStart(date.getTime());
      setEnd(date.getTime() + (1000 * 60 * 60 * 24 - 1));
    }
  };

  const handleFiltersChange = (filtersApplied: boolean) => {
    setHasFilters(filtersApplied);
  };

  const handleClearFilters = () => {
    if (alarmsTableRef.current) {
      alarmsTableRef.current.clearAllFilters();
    }
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className={classes.AlarmsListContainer}>
      <div className={classes.HeadRow}>
        <label className={classes.Label}>
          {start === end
            ? `${i18n.t("EventList")} (${DateTime.fromMillis(
                start
              ).toFormat("yyyy-MM-dd")}) - ${title} - ${alarms.length} ${i18n.t(
                "Alerts"
              )}`
            : `${i18n.t("EventList")} (${DateTime.fromMillis(
                start
              ).toFormat("yyyy-MM-dd")} - ${DateTime.fromMillis(
                end
              ).toFormat("yyyy-MM-dd")}) - ${title} - ${alarms.length} ${i18n.t(
                "Alerts"
              )}`}
        </label>
        <div className={classes.FilterControls}>
          {hasFilters && (
            <button
              className={classes.ClearFiltersButton}
              onClick={handleClearFilters}
            >
              {i18n.t("Clear Filters")}
            </button>
          )}
          <DatePickerComponent start={start} onDateChange={handleDateChange} />
        </div>
      </div>
      <div className={classes.TitleSeparator} />
      <div className={classes.TableContainer} style={{ maxHeight: height }}>
        <AlarmsTable
          ref={alarmsTableRef}
          alarms={alarms}
          onFiltersChange={handleFiltersChange}
        />
      </div>
      <div className={classes.DownloadButtonContainer}>
        <button
          className={classes.DownloadButton}
          onClick={() =>
            downloadAlarmsCSV(alarms, serial, start, end)
          }
        >
          <FontAwesomeIcon icon={faDownload as IconProp} />
          <span>{i18n.t("Export")}</span>
        </button>
      </div>
    </div>
  );
};
