// src/navigation/header/Header.tsx

import React, { Component } from "react";
import classes from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { getUsername, logout } from "../../api/Auth";
import i18n from "../../i18n";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

class Header extends Component {
  state = {
    isProfileSubmenuDisplayed: false,
    wrapperRef: React.createRef<HTMLDivElement>(),
    username: "",
  };

  constructor(props: any) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    this.setState({
      username: getUsername(),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (
      this.state.wrapperRef &&
      !this.state.wrapperRef.current!.contains(event.target)
    ) {
      if (this.state.isProfileSubmenuDisplayed === true) {
        this.setState({ isProfileSubmenuDisplayed: false });
      }
    }
  };

  render() {
    return (
      <div ref={this.state.wrapperRef} className={classes.HeaderContainer}>
        {/* Logo Section */}
        <div className={classes.HeaderLogo} onClick={() => window.location.href = "/"}>
        </div>

        {/* Profile Section */}
        <div
          className={classes.HeaderProfileContainer}
          onClick={() => {
            this.setState({
              isProfileSubmenuDisplayed: !this.state.isProfileSubmenuDisplayed,
            });
          }}
        >
          <div className={classes.HeaderProfileContent}>
            <FontAwesomeIcon icon={faUser as IconProp} className={classes.ProfileIcon} />
            <label>{this.state.username}</label>
            <FontAwesomeIcon icon={faChevronDown as IconProp} className={classes.ChevronIcon} />
          </div>

          {this.state.isProfileSubmenuDisplayed && (
            <div className={classes.HeaderLogoutContainer}>
              <div
                className={classes.HeaderLogoutContent}
                onClick={() => {
                  if (window.confirm(i18n.t("SureToDisconnect"))) {
                    logout();
                    window.location.replace("/");
                  }
                }}
              >
                <label>{i18n.t("Disconnect")}</label>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Header;
