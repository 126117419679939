// src/GlobalVue/Treeutils/helpers/handleDeleteNode.ts

import { Node } from '../types';
import { removeNodesById } from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';
import { findNodeByIdAndEnrich } from '../helpers';
interface HandleDeleteNodeParams {
  nodeId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  editNodeId: string | null;
  handleCancelEdit: (nodeId?: string) => void;
}

export const handleDeleteNodeLogic = async ({
  nodeId,
  data,
  setData,
  editNodeId,
  handleCancelEdit,
}: HandleDeleteNodeParams): Promise<void> => {
  const nodeToDelete = findNodeByIdAndEnrich(data, nodeId);
  if (!nodeToDelete) {
    console.error(`Node with ID ${nodeId} not found.`);
    return;
  }

  const clientid = nodeToDelete.originalParentId || ''; // Retrieve clientid
  if (!clientid) {
    console.error('Client ID is missing for the node.');
    return;
  }

  const idsToDelete = collectDescendantIds(nodeToDelete);

  const updatedData = removeNodesById(data, idsToDelete);
  setData(updatedData);

  try {
    const success = await callNodeCommandApi({
      nodeId,
      clientid, // Pass clientid here
      command: 'delete',
      data: { ids: idsToDelete },
    });
    if (!success) {
      setData(data);
    } else if (editNodeId === nodeId) {
      handleCancelEdit();
    }
  } catch (error) {
    console.error(`Error while deleting node with ID: ${nodeId}`, error);
    setData(data);
  }
};


/**
 * Collects all descendant node IDs of a given node.
 * @param node - The starting node.
 * @returns An array of all descendant node IDs, including the node itself.
 */
const collectDescendantIds = (node: Node): string[] => {
  let ids = [node.id]; // Start with the current node's ID
  if (node.children) {
    for (const child of node.children) {
      ids = ids.concat(collectDescendantIds(child)); // Recursively collect child IDs
    }
  }
  return ids;
};