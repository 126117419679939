import { t } from "i18next";
import i18n from "../i18n";
import { subDays, addDays } from "date-fns";
import { DateTime } from "luxon";

export const secToText = (sec: string): string => {
  const sec_num = parseInt(sec, 10);
  const days = Math.floor(sec_num / (3600 * 24));
  const hours = Math.floor((sec_num % (3600 * 24)) / 3600);
  const minutes = Math.floor((sec_num % 3600) / 60);
  const seconds = Math.ceil(sec_num % 60);

  return (
    (days > 0 ? `${days} ${i18n.t("d")} ` : "") +
    `${hours.toString().padStart(2, "0")}h ` +
    `${minutes.toString().padStart(2, "0")}min ` +
    `${seconds.toString().padStart(2, "0")}sec`
  );
};

export const secToTextWithoutUselessUnit = (sec: string): string => {
  const sec_num = parseInt(sec, 10);
  const days = Math.floor(sec_num / (3600 * 24));
  const hours = Math.floor((sec_num % (3600 * 24)) / 3600);
  const minutes = Math.floor((sec_num % 3600) / 60);
  const seconds = Math.ceil(sec_num % 60);

  return (
    (days > 0 ? `${days} ${i18n.t("d")} ` : "") +
    (hours > 0 ? `${hours} h ` : "") +
    (minutes > 0 ? `${minutes} min ` : "") +
    `${seconds} sec`
  );
};

export const secToMin = (sec: string): string => {
  const sec_num = parseInt(sec, 10);
  const minutes = Math.floor((sec_num % 3600) / 60);
  const seconds = Math.ceil(sec_num % 60);
  return (minutes === 0 ? "" : `${minutes} min `) + `${seconds} sec`;
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isTomorrow = (someDate: Date): boolean => {
  return someDate.getTime() > Date.now();
};

export const isValidTimestamp = (timestamp: number | string | undefined): boolean => {
  return timestamp !== undefined && !isNaN(new Date(timestamp).getTime());
};

export const timeAgoInSeconds = (timestamp: number): number => {
  return Math.floor(Date.now() / 1000 - timestamp);
};

export const getLastWeekInterval = (): [Date, Date] => {
  const now = new Date();
  const firstDayOfLastWeek = subDays(now, 7 + (now.getDay() - 1));
  firstDayOfLastWeek.setHours(0, 0, 0, 0);
  const lastDayOfLastWeek = addDays(firstDayOfLastWeek, 4);
  lastDayOfLastWeek.setHours(23, 59, 59, 0);
  return [firstDayOfLastWeek, lastDayOfLastWeek];
};

export const timestampToDate = (timestamp: number): string => {
  return new Intl.DateTimeFormat("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" }).format(new Date(timestamp));
};

export const getDaysBetweenTimestamps = (timestamp1: number, timestamp2: number): number => {
  return Math.ceil(Math.abs(new Date(timestamp2).getTime() - new Date(timestamp1).getTime()) / (1000 * 60 * 60 * 24));
};

export const convertUTCToLocal = (utcDateString: string): string | null => {
  return DateTime.fromISO(utcDateString, { zone: "utc" }).toLocal().toISO();
};