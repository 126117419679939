import { NodeDetails } from "../Treeutils/helpers/types";
import { deriveNodeType } from "./helpers/deriveNodeType";
import { callTableDataMapsApi } from "./helpers/api/initDatarequest";
import { TableColumn, TableRow } from "./helpers/types";

export async function getTableDataForNode(details: NodeDetails | null): Promise<{
  columns: TableColumn[];
  data: TableRow[];
  tableType: string; // Optional to indicate which table type
}[]> {
  if (!details || !details.node) {
    return [{ tableType: "unknown", columns: [], data: [] }];
  }
  console.log("details : ",details)

  const nodeType = deriveNodeType(details);

  // Use the API to fetch table data
  const apiResponse = await callTableDataMapsApi(details, nodeType);

  if (apiResponse) {
    // Process API response
    return apiResponse.map((tableData) => ({
      ...tableData,
      columns: [...tableData.columns],
    }));
  } else {
    console.warn("API response was null or invalid for nodeType:", nodeType);
    return [{ tableType: "unknown", columns: [], data: [] }];
  }
}
