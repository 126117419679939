import React, { useState, useCallback, useEffect } from 'react';
import { Tree, NodeRendererProps } from 'react-arborist';
import styles from './styles/Tree.module.css';

import { Node, NodeDetails } from './Treeutils/helpers/types';
import { searchMatch } from './Treeutils/helpers/searchMatch';
import { useTreeHandlers } from './Treeutils/helpers/useTreeHandlers';
import TreeNode from './Treeutils/components/TreeNode';
import { NodeId } from './Treeutils/helpers/types';
import ConfirmationModal from './Treeutils/components/ConfirmationModal';
import { NodeApi } from 'react-arborist';
import { RowData } from './tablecomponent'; // Import RowData type

interface TreeComponentProps {
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  searchTerm: string;
  onNodeDoubleClick: (details: NodeDetails) => void;
  setTableConfig: React.Dispatch<
    React.SetStateAction<{ tableType: string; data: RowData[] }[]>
  >; // Prop for updating table data
  currentTab: string; // Prop for the current active table tab
}

const TreeComponent: React.FC<TreeComponentProps> = ({
  data,
  setData,
  searchTerm,
  onNodeDoubleClick,
  setTableConfig,
  currentTab,
}) => {
  const [editNodeId, setEditNodeId] = useState<NodeId | null>(null);
  const [newNodeName, setNewNodeName] = useState('');
  const [newlyAddedNodeId, setNewlyAddedNodeId] = useState<NodeId | null>(null);
  const [treeHeight, setTreeHeight] = useState<number>(window.innerHeight - 150);

  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState<any>(null);

  const showModalPromise = (props: any): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalProps({
        ...props,
        onConfirm: () => {
          resolve(true);
          setShowModal(false);
        },
        onCancel: () => {
          resolve(false);
          setShowModal(false);
        },
      });
      setShowModal(true);
    });
  };

  useEffect(() => {
    const updateHeight = () => setTreeHeight(window.innerHeight - 150);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const {
    handleCancelEdit,
    handleDeleteNode,
    handleSaveEdit,
    handleEditNode,
    handleAddNode,
    handleAssociateToggle,
    handleMoveNode,
    handleSortChildren,
    sortingOrders,
  } = useTreeHandlers({
    data,
    setData,
    editNodeId,
    setEditNodeId,
    newNodeName,
    setNewNodeName,
    newlyAddedNodeId,
    setNewlyAddedNodeId,
    showModal: showModalPromise,
  });

  const onMove = useCallback(
    async (params) => {
      await handleMoveNode(params);
    },
    [handleMoveNode]
  );

  const handleRemoveTableRow = (serial: string) => {
    console.log("Call HandleRemoveTableRow");
    console.log("Current Tab:", currentTab);
    console.log("serial : ", serial)
  
    setTableConfig((prevConfig: { tableType: string; data: RowData[] }[]) =>
      prevConfig.map((table) =>
        table.tableType === currentTab
          ? { ...table, data: table.data.filter((row: RowData) => row.serial !== serial) }
          : table
      )
    );
  };

  const handleNodeSelect = (node: NodeApi<Node> | NodeApi<Node>[]) => {
    const selectedNodeApi = Array.isArray(node) ? node[0] : node;
    if (!selectedNodeApi) return;

    const selectedNode = selectedNodeApi.data;
    const parentNode = selectedNodeApi.parent?.data || null;
    const grandparentNode = selectedNodeApi.parent?.parent?.data || null;

    const nodeDetails = {
      node: selectedNode,
      parent: parentNode,
      grandparent: grandparentNode,
    };
    console.log('Node Details:', nodeDetails);

    onNodeDoubleClick(nodeDetails);
  };

  return (
    <>
      <div className={styles.treeContainer}>
        <Tree
          data={data}
          onMove={onMove}
          openByDefault={true}
          rowHeight={30}
          width="100%"
          height={treeHeight}
          indent={20}
          searchTerm={searchTerm}
          searchMatch={searchMatch}
          padding={10}
          paddingTop={15}
          paddingBottom={15}
          className={styles.treeComponent}
          onSelect={(node) => handleNodeSelect(node)}
        >
          {(nodeProps: NodeRendererProps<Node>) => (
            <TreeNode
              {...nodeProps}
              data={data}
              setData={setData}
              showModal={showModalPromise}
              handleRemoveTableRow={handleRemoveTableRow}
              editNodeId={editNodeId}
              newNodeName={newNodeName}
              newlyAddedNodeId={newlyAddedNodeId}
              setNewNodeName={setNewNodeName}
              handleSaveEdit={handleSaveEdit}
              handleCancelEdit={handleCancelEdit}
              handleEditNode={handleEditNode}
              handleDeleteNode={handleDeleteNode}
              handleAddNode={handleAddNode}
              handleAssociateToggle={handleAssociateToggle}
              handleSortChildren={handleSortChildren}
              sortingOrder={sortingOrders[nodeProps.node.id]}
            />
          )}
        </Tree>
      </div>

      {showModal && (
        <ConfirmationModal
          nodeName={modalProps.nodeName}
          fromCompany={modalProps.fromCompany}
          toCompany={modalProps.toCompany}
          onConfirm={modalProps.onConfirm}
          onCancel={modalProps.onCancel}
        />
      )}
    </>
  );
};

export default TreeComponent;
