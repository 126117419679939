import { findNodeByIdAndEnrich} from "../helpers";
import { handleMove } from "./handleMove";
import { Node } from "../types";

export const draggedTableNodeHandler = async (
    item: { id: string; ref: string; serial: string },
    rootNodeId: string,
    nodeId: string,
    data: Node[],
    setData: React.Dispatch<React.SetStateAction<Node[]>>,
    showModal: (props: {
      nodeName: string;
      fromCompany: string;
      toCompany: string;
      onConfirm: () => void;
      onCancel: () => void;
    }) => Promise<boolean>,
    updateTableStatus: (id: string) => void // Function to remove row from table
  ): Promise<void> => {
    const searchref = `securispot-${item.serial}`;
  
    // Find the node in the tree
    const targetNode = findNodeByIdAndEnrich(data, searchref);
    if (!targetNode) {
      console.error(`Node with ID ${searchref} not found in the tree.`);
      return;
    }
  
    // Call handleMove to move the node
    const moveSuccessful = await handleMove(
      {
        dragIds: [searchref],
        parentId: nodeId,
        index: 0, // Adjust the index as needed
      },
      data,
      setData,
      showModal
    );
  
    // If move is successful, update the table
    if (moveSuccessful) {
      console.log(`Removing row with ID: ${item.id} from the table.`);
      updateTableStatus(item.serial); // Call to remove the row
    } else {
      console.error('Failed to move the node.');
    }
  };
  