import React, { useEffect, useRef, useState } from "react";
import { Chart, ChartDataSets } from "chart.js";
import { Trans } from "react-i18next";
import i18n from "../i18n";
import classes from "./Graph.module.css";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faRedo } from "@fortawesome/free-solid-svg-icons";
import { GraphType } from "./GraphTypes";
import { createChart } from "../utils/ChartUtils";
import { Interval } from "../navigation/tabs/TabsTypes";
import {
  getIncidentsEvolutionDataset,
  getStatusEvolutionDataset,
  getAlarmsEvolutionDataset,
  getAlarmsEvolutionPerTypesDataset,
} from "./datasets/datasets";
import {
  getAlertsPerType,
  getAlertsEvolution,
  getAvailabilityEvolution,
} from "../api/DetectionsRequests";
import { processData } from "../helpers/AlarmProcessing";
import { AIButton, AIModal } from "../ai/AIComponents";
import { fetchAIResponse } from "../ai/AIService";
import { ApiDataResponse } from "../api/Request";
import { exportToCSV } from "../utils/exportToCSV"; // Import the CSV export function
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface IProps {
  graphType: GraphType;
  interval: Interval;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  nodePath: string;
  mediaspotSerial?: string;
  mediaspotSerials?: string[];
  preventUpdateAnimations: boolean;
  securispot: string;
  export: boolean;
}

export const Graph = (props: IProps) => {
  const [isContentLoaded, setContentLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [noData, setNoData] = useState<boolean>(false);
  const [apiResponseData, setApiResponseData] = useState<
    ApiDataResponse<any> | undefined
  >(undefined);
  const [aiResponse, setAIResponse] = useState<string | undefined>(undefined);
  const [showAIModal, setShowAIModal] = useState<boolean>(false);
  const [isRequestingAI, setIsRequestingAI] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ChartDataSets[] | undefined>(
    undefined
  );
  const [chartInstance, setChartInstance] = useState<Chart | undefined>(
    undefined
  );
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [dashboardKey, setDashboardKey] = useState<number>(0);

  const refreshDashboard = () => setDashboardKey((prevKey) => prevKey + 1);

  const handleAIRequest = async () => {
    if (apiResponseData) {
      await fetchAIResponse(
        apiResponseData.data,
        setAIResponse,
        setShowAIModal,
        setIsRequestingAI,
        props.graphType
      );
    }
  };

  useEffect(() => {
    // Clean up chart instance when the component unmounts
    return () => {
      chartInstance?.destroy(); // Optional chaining, cleaner syntax
    };
  }, [chartInstance]);
  
  useEffect(() => {
    let isMounted = true; // Flag to track if component is still mounted
  
    // Safely refresh dashboard and fetch data
    if (isMounted) {
      refreshDashboard();
      getData();
    }
  
    return () => {
      isMounted = false; // Clean up when the component is unmounted
    };
  }, [
    props.interval,
    props.startDatePeriodTimestamp,
    props.endDatePeriodTimestamp,
    props.nodePath,
    props.securispot,
  ]);
  
  useEffect(() => {
    let isMounted = true; // Flag to track component mounted state
  
    if (apiResponseData && isMounted) {
      setGraphData(); // Set graph data safely only if component is still mounted
    }
  
    return () => {
      isMounted = false; // Clean up on unmount to prevent state updates
    };
  }, [apiResponseData]);
  
  const getData = async () => {
    setContentLoaded(false);
    setError(undefined);
    setNoData(false);

    const dataMap: Record<GraphType, (() => Promise<ApiDataResponse<any>>) | undefined> = {
      [GraphType.INCIDENTS_COUNT]: undefined,
      [GraphType.ALARMS_COUNT]: undefined,
      [GraphType.AREA_IN_ALARMS_EVOLUTION]: () =>
        getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          1,
          props.securispot
        ),
      [GraphType.HAT_ALARMS_EVOLUTION]: () =>
        getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          2, // Assuming '2' is the type for HatWearing alarms
          props.securispot
        ),
      [GraphType.LYING_PERSON_ALARMS_EVOLUTION]: () =>
        getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          3, // Assuming '3' is the type for Lying Person alarms
          props.securispot
        ),
      [GraphType.ALARMS_EVOLUTION]: () =>
        getAlertsEvolution(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          props.securispot
        ),
      [GraphType.STATUS_EVOLUTION]: () =>
        getAvailabilityEvolution(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          props.securispot
        ),
      [GraphType.OVERLOADED_AREA_ALARM]: () =>
        getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          4,
          props.securispot
        ),
      [GraphType.COLLISION_ALARM_EVOLUTION]: () =>
        getAlertsPerType(
          props.startDatePeriodTimestamp,
          props.endDatePeriodTimestamp,
          props.interval,
          5,
          props.securispot
        ),
        
    };
    
    const fetchData = dataMap[props.graphType];
    if (!fetchData) {
      setError("Data fetcher not defined for this graph type");
      setContentLoaded(true);
      return;
    }
    try {
    const data = await fetchData();
    if (!data || data.error || !data.data || data.data.length === 0) {
      setApiResponseData({
        error: data?.error || "No data response",
        data: [],
      });
      setContentLoaded(true);
      return;
    }

    const processedData = processData(
      props.graphType,
      data,
      props.interval,
      props.startDatePeriodTimestamp,
      props.endDatePeriodTimestamp
    );
    

    setApiResponseData(processedData || { error: '', data: [] });
    setError(undefined);
    // Do not set isContentLoaded here; it will be set in setGraphData
  } catch (error) {
    console.error('Error fetching data:', error);
    setError("Failed to fetch data");
    setContentLoaded(true);
    return;
  }
  };
  const setGraphData = () => {
    const datasetMap: Record<GraphType, (() => any) | undefined> = {
      [GraphType.INCIDENTS_COUNT]: () => getIncidentsEvolutionDataset(),
      [GraphType.ALARMS_COUNT]: undefined,
      [GraphType.AREA_IN_ALARMS_EVOLUTION]: () =>
        getAlarmsEvolutionDataset(apiResponseData?.data),
      [GraphType.HAT_ALARMS_EVOLUTION]: () =>
        getAlarmsEvolutionDataset(apiResponseData?.data),
      [GraphType.LYING_PERSON_ALARMS_EVOLUTION]: () =>
        getAlarmsEvolutionDataset(apiResponseData?.data),
      [GraphType.ALARMS_EVOLUTION]: () =>
        getAlarmsEvolutionPerTypesDataset(apiResponseData?.data),
      [GraphType.STATUS_EVOLUTION]: () =>
        getStatusEvolutionDataset(apiResponseData?.data),
      [GraphType.OVERLOADED_AREA_ALARM]: () =>
        getAlarmsEvolutionDataset(apiResponseData?.data),
      [GraphType.COLLISION_ALARM_EVOLUTION]: () =>
        getAlarmsEvolutionDataset(apiResponseData?.data),
    };

    const datasetFetcher = datasetMap[props.graphType];
    if (!datasetFetcher) {
      setError("Dataset generator not defined for this graph type");
      setContentLoaded(true);
      return;
    }

    const resp = datasetFetcher();

    if (!resp || resp.error) {
      setError(resp?.error || "Invalid dataset");
      setContentLoaded(true);
      return;
    }

    if (resp.error) {
      setError("Invalid dataset");
      setContentLoaded(true);
      return;
    }

    setChartData(resp.datasets);
    createAndSetChart(
      chartRef,
      resp.datasets,
      resp.dates,
      resp.labels,
      resp.maxValue
    );
    // setContentLoaded(true); // Will be set in createAndSetChart
  };

  const createAndSetChart = (
    ref: React.RefObject<HTMLCanvasElement>,
    datasets: ChartDataSets[],
    dates: Date[],
    labels?: string[] | Array<string[]>,
    maxValue?: number
  ) => {
    const ctx = ref.current?.getContext("2d");
    if (chartInstance) chartInstance.destroy();

    if (datasets.find((it) => it.data && it.data.length > 0) === undefined) {
      setNoData(true);
      setContentLoaded(true);
      return;
    }

    if (datasets && ctx) {
      const chart = createChart({
        ctx,
        graphType: props.graphType,
        datasets,
        dates,
        labels,
        maxValue,
        interval: props.interval,
        startDatePeriodTimestamp: props.startDatePeriodTimestamp,
        endDatePeriodTimestamp: props.endDatePeriodTimestamp,
        preventUpdateAnimations: props.preventUpdateAnimations,
      });
      setChartInstance(chart);
    }
    setContentLoaded(true);
  };

  const downloadData = () => {
    exportToCSV({
      chartData: chartData!,
      interval: props.interval,
      securispot: props.securispot,
      startDatePeriodTimestamp: props.startDatePeriodTimestamp,
      endDatePeriodTimestamp: props.endDatePeriodTimestamp,
      graphType: props.graphType, // Pass graphType here
    });
  };

  return (
    <div className={classes.GraphContainer} key={dashboardKey}>
      {/* Loader Spinner */}
      {!isContentLoaded && !error && (
        <div className={classes.GraphSpinnerContainer}>
          <Loader type="Oval" color="#004aac" height={50} width={50} />
        </div>
      )}
  
      {/* No Data Message */}
      {noData && (
        <div className={classes.GraphSpinnerContainer}>
          <label>
            <Trans>NoDataOnThisPeriod</Trans>
          </label>
        </div>
      )}
  
      {/* Error Handling */}
      {error && (
        <div className={classes.GraphSpinnerContainer}>
          <label>
            <Trans>AnErrorOccurredDuringLoading</Trans>...
          </label>
          <br />
          <button className={classes.RetryButton} onClick={getData}>
            <FontAwesomeIcon icon={faRedo as IconProp} size="sm" color="#404040" />
            &nbsp;&nbsp;<Trans>Reload</Trans>
          </button>
        </div>
      )}
  
      {/* Chart Rendering */}
      <div className={classes.ChartWrapper}>
        <canvas onMouseDown={(e) => e.stopPropagation()} ref={chartRef} />
      </div>
  
      {/* Button Container (Export and AI) */}
      <div className={classes.ButtonContainer}>
        {/* Export Button */}
        {props.export && (
          <button className={classes.DownloadButton} onClick={downloadData}>
            <FontAwesomeIcon icon={faDownload as IconProp} />
            <span>{i18n.t("Export")}</span>
          </button>
        )}
  
        {/* Conditionally render AI Button and AI Modal if Interval is Hour */}
        {props.interval === Interval.HOUR && (
          <>
            {/* AI Button */}
            <AIButton
              isContentLoaded={isContentLoaded}
              noData={noData}
              isRequestingAI={isRequestingAI}
              handleAIRequest={handleAIRequest}
            />
  
            {/* AI Modal */}
            <AIModal
              showAIModal={showAIModal}
              isRequestingAI={isRequestingAI}
              aiResponse={aiResponse}
              setShowAIModal={setShowAIModal}
              handleAIRequest={handleAIRequest}
              apiResponseData={apiResponseData}
              dataType="chart"
              graphType={props.graphType}
              interval={props.interval}
              startDatePeriodTimestamp={props.startDatePeriodTimestamp}
              endDatePeriodTimestamp={props.endDatePeriodTimestamp}
              preventUpdateAnimations={props.preventUpdateAnimations}
            />
          </>
        )}
      </div>
    </div>
  );
};
