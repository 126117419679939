import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { NodeRendererProps } from 'react-arborist';

import styles from './styles/TreeNode.module.css';
import { Node } from '../helpers/types';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { NodeId, NodeHandler, ParentNodeHandler } from '../helpers/types';

import { useNodeProperties, getNodeClasses } from './TreeNodehelpers/helpers';
import {
  renderExpandCollapseArrow,
  renderNodeIcon,
  renderNodeLabelOrEditInput,
  renderActionButtons,
} from './TreeNodehelpers/componentshelpers';
import { draggedTableNodeHandler } from '../helpers/handlers/draggedTableNodehandler';

interface TreeNodeProps extends NodeRendererProps<Node> {
  editNodeId: NodeId | null;
  newNodeName: string;
  newlyAddedNodeId: NodeId | null;
  setNewNodeName: React.Dispatch<React.SetStateAction<string>>;

  handleSaveEdit: (nodeId: NodeId) => void;
  handleCancelEdit: (nodeId?: NodeId) => void;
  handleEditNode: NodeHandler;
  handleDeleteNode: NodeHandler;
  handleAddNode: ParentNodeHandler;
  handleAssociateToggle: NodeHandler;
  sortingOrder?: 'asc' | 'desc';
  handleSortChildren: (nodeId: string) => void;
  handleRemoveTableRow: (serial: string) => void; // Correctly typed as a function
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  showModal: (props: {
    nodeName: string;
    fromCompany: string;
    toCompany: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => Promise<boolean>;
}


// Must match the 'type' used in your table's draggable rows
const DRAG_TYPE = 'UNASSOCIATED_ROW';

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  style,
  dragHandle,  // from react-arborist for internal reordering
  editNodeId,
  newNodeName,
  setNewNodeName,
  handleSaveEdit,
  handleCancelEdit,
  handleEditNode,
  handleDeleteNode,
  handleAddNode,
  handleAssociateToggle,
  sortingOrder,
  handleSortChildren,
  handleRemoveTableRow,
  data,
  setData,
  showModal,
}) => {
  
  // 1) Gather node-specific logic
  const {
    hasChildren,
    isSecurispotDevice,
    isCompanyNode,
    isLeafNode,
    showAssociateButton,
    isUnderSecurispotNA,
    isEditing,
    ifsecurispotstatus,
  } = useNodeProperties(node, editNodeId, setNewNodeName);

  // 2) Root node check (for showing the sort button)
  const isRootNode = node.parent?.parent === undefined;

  // 3) Determine if this node is "securispot" => skip drop
  const isSecurispot = node.id.startsWith('securispot');

  // 4) Set up React DnD for external drop
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: DRAG_TYPE,
    canDrop: (item: any) => {
      // Only allow drop if not securispot & item.id === 'UNASSOCIATED'
      if (isSecurispot) return false;
      return item.id === 'UNASSOCIATED';
    },
    drop: async (item: any) => {
      console.log('item :', item);
      await draggedTableNodeHandler(
        item,
        'securispot-NA', // Replace with the actual root node ID for Securispot NA
        node.id,
        data,
        setData,
        showModal,
        handleRemoveTableRow
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  // 5) Combine arborist's drag handle & react-dnd drop ref
  //    so you don't need extra containers or break layout
  const combinedRef = useCallback(
    (element: HTMLDivElement | null) => {
      // Use optional chaining or a type check before calling dragHandle
      dragHandle?.(element);
  
      // Attach drop ref only if not securispot
      if (!isSecurispot && element) {
        dropRef(element);
      }
    },
    [dragHandle, dropRef, isSecurispot]
  );

  // 6) Subtle highlight if we are over a valid drop target
  const highlightStyle: React.CSSProperties = isOver && canDrop
    ? { backgroundColor: 'rgba(0, 255, 0, 0.15)' }
    : {};

  // 7) Compute node classes from your existing logic
  const nodeClasses = getNodeClasses(
    styles,
    hasChildren,
    node.isOpen,
    isLeafNode,
    node.willReceiveDrop,
    isSecurispotDevice
  );

  // 8) Render your existing layout
  return (
    <div ref={combinedRef} className={nodeClasses} style={{ ...style, ...highlightStyle }}>
      {renderExpandCollapseArrow(hasChildren, node, styles)}
      {renderNodeIcon(isCompanyNode, isSecurispotDevice, isLeafNode, styles, ifsecurispotstatus)}
      <div className={styles.nodeContent}>
        <div className={styles.nodeLabelContainer}>
          {renderNodeLabelOrEditInput(
            isEditing,
            newNodeName,
            setNewNodeName,
            node.data.name,
            node.id,
            handleSaveEdit,
            handleCancelEdit,
            styles
          )}
          {isRootNode && (
            <button
              className={styles.sortButton}
              onClick={() => handleSortChildren(node.id)}
            >
              {sortingOrder === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
            </button>
          )}
        </div>

        {renderActionButtons(
          showAssociateButton,
          node.id,
          isUnderSecurispotNA,
          handleAssociateToggle,
          handleEditNode,
          handleDeleteNode,
          handleAddNode
        )}
      </div>
    </div>
  );
};

export default TreeNode;
