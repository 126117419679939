import { callTreebuilder } from "./api/TreeInitialDataapi";
import { Node } from "./types";

/**
 * Recursively enriches the tree with `originalParentId`.
 * @param nodes - The tree nodes to enrich.
 * @param rootId - The ID of the root node to assign.
 * @returns The enriched tree nodes.
 */
const enrichTreeWithRootId = (nodes: Node[], rootId: string = ''): Node[] => {
  return nodes.map((node) => {
    const validRootId = rootId || node.id; // Ensure `rootId` is a valid string
    const enrichedNode: Node = { ...node, originalParentId: validRootId };
    if (node.children) {
      enrichedNode.children = enrichTreeWithRootId(node.children, validRootId);
    }
    return enrichedNode;
  });
};


/**
 * Fetches the initial tree data and enriches it with `rootId`.
 * @returns The enriched tree data.
 */
export const initialData = async (): Promise<Node[]> => {
  try {
    // Fetch the tree data from the backend
    const fetchedTreeData = await callTreebuilder();
    if (fetchedTreeData) {
      return enrichTreeWithRootId(fetchedTreeData);
    }
  } catch (error) {
    console.error("Error fetching tree data:", error);
  }

  // Fallback data in case fetching fails
  const fallbackData: Node[] = [
    {
      id: '1',
      name: 'Parent Node 1',
      hello: "Hello",
      children: [
        {
          id: '1-1',
          name: 'Child Node 1.1',
        },
        {
          id: '1-2',
          name: 'Child Node 1.2',
          children: [
            {
              id: '1-2-1',
              name: 'Child Node 1.2.1',
              children: [
                {
                  id: '1-2-1-1',
                  name: 'Child Node 1.2.1.1',
                },
                {
                  id: '1-2-1-2',
                  name: 'Child Node 1.2.1.2',
                },
                {
                  id: '1-2-1-3',
                  name: 'Child Node 1.2.1.3',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '2',
      name: 'Parent Node 2',
    },
  ];

  return enrichTreeWithRootId(fallbackData);
};
