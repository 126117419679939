import { NodeApi } from 'react-arborist';
import { Node } from '../../helpers/types';

// Helper function to check if the node is under "Securispot NA"
export function checkIfUnderSecurispotNA(node: NodeApi<Node>): boolean {
    let currentNode = node.parent;
    while (currentNode) {
      if (currentNode.data.name === 'Securispot NA') {
        return true;
      }
      currentNode = currentNode.parent;
    }
    return false;
  }

export const useNodeProperties = (
  node: NodeApi<Node>,
  editNodeId: string | null,
  setNewNodeName: React.Dispatch<React.SetStateAction<string>>
) => {
  const hasChildren = node.children?.length !== 0;
  const isSecurispotDevice = node.id.includes('securispot');
  const isCompanyNode = node.parent?.parent == null;
  const isLeafNode = node.children?.length === 0;
  const isEventType = !node.data.name.includes('fp-');
  const showAssociateButton = isLeafNode && !isEventType;
  const isUnderSecurispotNA = checkIfUnderSecurispotNA(node);
  const isEditing = editNodeId === node.id;
  const ifsecurispotstatus = node.data.status
  
  return {
    hasChildren,
    isSecurispotDevice,
    isCompanyNode,
    isLeafNode,
    isEventType,
    showAssociateButton,
    isUnderSecurispotNA,
    isEditing,
    ifsecurispotstatus,
  };
};

export const getNodeClasses = (
    styles: any,
    hasChildren: boolean,
    nodeIsOpen: boolean,
    isLeafNode: boolean,
    willReceiveDrop: boolean,
    isSecurispotDevice: boolean
  ) => {
    const nodeClasses = [
      styles.treeNode,
      hasChildren ? (nodeIsOpen ? styles.expanded : styles.collapsed) : styles.leaf,
      willReceiveDrop && isSecurispotDevice ? styles.cannotDrop : '',
    ].join(' ');
  
    return nodeClasses;
  };
  