// src/GlobalVue/Treeutils/helpers/useTreeHandlers.ts

import { useCallback, useState } from 'react';
import { Node } from './types';
import {
  removeNodesById,
  findNodeByIdAndEnrich,
} from './helpers';
import { NodeId, NodeHandler, ParentNodeHandler } from './types';
import { handleAssociateToggleLogic } from './handlers/associateNode';
import { handleMove } from './handlers/handleMove';
import { handleDeleteNodeLogic } from './handlers/handleDeleteNode';
import { handleSaveEditLogic } from './handlers/handleSaveEdit';
import { handleAddNodeLogic } from './handlers/handleAddNode';
import { handleSortChildrenLogic } from './handlers/handleSortChildren';

interface UseTreeHandlersParams {
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  editNodeId: NodeId | null;
  setEditNodeId: React.Dispatch<React.SetStateAction<NodeId | null>>;
  newNodeName: string;
  setNewNodeName: React.Dispatch<React.SetStateAction<string>>;
  newlyAddedNodeId: NodeId | null;
  setNewlyAddedNodeId: React.Dispatch<React.SetStateAction<NodeId | null>>;
  showModal: (props: {
    nodeName: string;
    fromCompany: string;
    toCompany: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => Promise<boolean>;
}

export const useTreeHandlers = ({
  data,
  setData,
  editNodeId,
  setEditNodeId,
  newNodeName,
  setNewNodeName,
  newlyAddedNodeId,
  setNewlyAddedNodeId,
  showModal,
}: UseTreeHandlersParams) => {
  const [sortingOrders, setSortingOrders] = useState<{ [key: string]: 'asc' | 'desc' }>({});

  // Define handleSortChildren
  const handleSortChildren = useCallback(
    (nodeId: string) => {
      handleSortChildrenLogic({
        nodeId,
        data,
        setData,
        sortingOrders,
        setSortingOrders,
      });
    },
    [data, setData, sortingOrders, setSortingOrders]
  );
  // Define handleCancelEdit
  const handleCancelEdit = useCallback(
    (nodeId?: NodeId) => {
      if (nodeId && nodeId === newlyAddedNodeId) {
        // If it's a new node and the user cancels, remove it
        const updatedData = removeNodesById(data, [nodeId]);
        setData(updatedData);
        setNewlyAddedNodeId(null);
      }
      setEditNodeId(null);
      setNewNodeName('');
    },
    [data, setData, newlyAddedNodeId, setEditNodeId, setNewNodeName, setNewlyAddedNodeId]
  );

  // Define handleDeleteNode
  const handleDeleteNode: NodeHandler = useCallback(
    async (nodeId) => {
      await handleDeleteNodeLogic({
        nodeId,
        data,
        setData,
        editNodeId,
        handleCancelEdit,
      });
    },
    [data, setData, editNodeId, handleCancelEdit]
  );

  // Define handleSaveEdit
  const handleSaveEdit = useCallback(
    async (nodeId: NodeId) => {
      await handleSaveEditLogic({
        nodeId,
        data,
        setData,
        newNodeName,
        newlyAddedNodeId,
        handleCancelEdit,
        handleDeleteNode,
      });
    },
    [data, newNodeName, newlyAddedNodeId, setData, handleDeleteNode, handleCancelEdit]
  );

  // Define handleEditNode
const handleEditNode: NodeHandler = useCallback(
  async (nodeId) => {
    const node = findNodeByIdAndEnrich(data, nodeId);
    if (node) {
      setEditNodeId(nodeId);
      setNewNodeName(node.name);
      setNewlyAddedNodeId(null); // Reset the newly added node ID
    }
  },
  [data, setEditNodeId, setNewNodeName, setNewlyAddedNodeId]
);


  // Define handleAddNode
  const handleAddNode: ParentNodeHandler = useCallback(
    async (parentId) => {
      await handleAddNodeLogic({
        parentId,
        data,
        setData,
        setEditNodeId,
        setNewNodeName,
        setNewlyAddedNodeId,
      });
    },
    [data, setData, setEditNodeId, setNewNodeName, setNewlyAddedNodeId]
  );

  const handleAssociateToggle: NodeHandler = useCallback(
    async (nodeId) => {
      await handleAssociateToggleLogic(nodeId, data, setData);
    },
    [data, setData]
  );

  // Define handleMoveNode as a wrapper around handleMove
  const handleMoveNode = useCallback(
    async (params: { dragIds: string[]; parentId: string | null; index: number }) => {
      return await handleMove(params, data, setData, showModal);
    },
    [data, setData, showModal]
  );

  return {
    handleCancelEdit,
    handleDeleteNode,
    handleSaveEdit,
    handleEditNode,
    handleAddNode,
    handleAssociateToggle,
    handleMoveNode,
    handleSortChildren,
    sortingOrders,
  };
};
