
import { FaChevronRight, FaChevronDown, FaWifi, FaBuilding, FaMinus } from 'react-icons/fa';
import { NodeId, NodeHandler, ParentNodeHandler } from '../../helpers/types';
import ActionButtons from '../ActionButtons';
import AssociateButton from '../AssociateButton';
// Function to render expand/collapse arrow
export const renderExpandCollapseArrow = (hasChildren: boolean, node: any, styles: any) => {
    if (hasChildren) {
      return (
        <span className={styles.arrowicon} onClick={() => node.toggle()}>
          {node.isOpen ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      );
    }
    return null;
  };
  
  // Function to render the appropriate node icon
  export const renderNodeIcon = (
    isCompanyNode: boolean,
    isSecurispotDevice: boolean,
    isLeafNode: boolean,
    styles: any,
    ifsecurispotstatus?: string
  ) => {
    // If it's a Securispot device, decide the color class
    let wifiClass = styles.securispotIcon; // default
    if (isSecurispotDevice) {
      if (!ifsecurispotstatus || ifsecurispotstatus === 'IN_STOCK') {
        wifiClass = styles.securispotIconRed;
      } else if (ifsecurispotstatus === 'ACTIVE') {
        wifiClass = styles.securispotIconBlue;
      }
    }
  
    const spanClass = isCompanyNode
      ? styles.companyIcon
      : isSecurispotDevice
      ? wifiClass
      : isLeafNode
      ? styles.defaultIcon
      : undefined;
  
    return (
      <span className={spanClass}>
        {isCompanyNode ? (
          <FaBuilding />
        ) : isSecurispotDevice ? (
          <FaWifi />
        ) : isLeafNode ? (
          <FaMinus />
        ) : null}
      </span>
    );
  };
  
  
  // Function to render the node label or input for editing
export const renderNodeLabelOrEditInput = (
    isEditing: boolean,
    newNodeName: string,
    setNewNodeName: React.Dispatch<React.SetStateAction<string>>,
    nodeName: string, // Change this to accept the node name
    nodeId: NodeId,
    handleSaveEdit: (nodeId: NodeId) => void,
    handleCancelEdit: (nodeId?: NodeId) => void,
    styles: any
  ) => {
    if (isEditing) {
      return (
        <input
          type="text"
          value={newNodeName}
          onChange={(e) => setNewNodeName(e.target.value)}
          onBlur={() => handleSaveEdit(nodeId)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSaveEdit(nodeId);
            if (e.key === 'Escape') handleCancelEdit(nodeId);
          }}
          autoFocus
          className={styles.editInput}
        />
      );
    }
    return <span className={styles.nodeLabel}>{nodeName}</span>;
  };
  
// Function to render action buttons
export const renderActionButtons = (
  showAssociateButton: boolean,
  nodeId: NodeId,
  isUnderSecurispotNA: boolean,
  handleAssociateToggle: NodeHandler,
  handleEditNode: NodeHandler,
  handleDeleteNode: NodeHandler,
  handleAddNode: ParentNodeHandler
) => {
  return showAssociateButton ? (
    <AssociateButton
      nodeId={nodeId}
      isAssociated={!isUnderSecurispotNA}
      onAssociateToggle={handleAssociateToggle}
      disabled={isUnderSecurispotNA}
    />
  ) : (
    <ActionButtons
      nodeId={nodeId}
      onEdit={handleEditNode}
      onDelete={handleDeleteNode}
      onAddChild={handleAddNode}
    />
  );
};
