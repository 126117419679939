import { Node } from '../types';
import { 
  findNodeByIdAndEnrich, 
  removeNodeAndGetParentId, 
  findOrCreateSecurispotNode, 
  updateAssociationStatus
} from '../helpers';

/**
 * Toggles the association status of a node between a client and "Securispot NA".
 *
 * Requirements:
 * - If the node is currently in "Securispot NA", the next step is "associate" => move it to its (new) client.
 *   --> The API must get the "after toggle" parent's ID in both clientid & parentId.
 * - If the node is currently under a client, the next step is "disassociate" => move it into "Securispot NA".
 *   --> The API must get the "before toggle" parent's ID in both clientid & parentId.
 */
export const handleAssociateToggleLogic = async (
  nodeId: string,
  data: Node[],
  setData: React.Dispatch<React.SetStateAction<Node[]>>
): Promise<void> => {
  try {
    // 1) Find the node in the original tree
    const node = findNodeByIdAndEnrich(data, nodeId);
    if (!node) {
      console.error(`Node with ID ${nodeId} not found. Aborting toggle logic.`);
      return;
    }

    // 2) Remove node from the current tree
    //    This yields updatedData + the node’s old parent ID.
    const { updatedData, parentId: oldParentId } = removeNodeAndGetParentId(data, nodeId);

    if (!updatedData) {
      console.error("Updated data is null/undefined after node removal. Aborting toggle logic.");
      return;
    }

    // We'll clone the node so we can reattach it
    const updatedNode: Node = { ...node };

    // 3) Find (or create) the "Securispot NA" node
    const securispotNode = findOrCreateSecurispotNode(updatedData);
    if (!securispotNode) {
      console.error("Could not find or create 'Securispot NA' node. Aborting toggle logic.");
      return;
    }
    if (!securispotNode.children) {
      securispotNode.children = [];
    }

    // 4) Check if the node was in Securispot already
    //    (i.e. "associate" if in Securispot, "disassociate" if in a client)
    const wasInSecurispot = securispotNode.id === node.parentId;
    const isAssociateCommand = wasInSecurispot; // True => associate; false => disassociate

    // We'll store "newParentId" after we attach the node locally
    let newParentId: string | null = null;

    // 5) Reattach the node in local data
    if (wasInSecurispot) {
      // --> "associate": move node from Securispot to its old client
      securispotNode.children = securispotNode.children.filter(child => child.id !== nodeId);

      if (oldParentId) {
        const oldParent = findNodeByIdAndEnrich(updatedData, oldParentId);
        if (oldParent) {
          if (!oldParent.children) {
            oldParent.children = [];
          }
          oldParent.children.push(updatedNode);
          updatedNode.parentId = oldParentId;
          newParentId = oldParentId;
        } else {
          console.warn(
            `Could not find old parent (ID: ${oldParentId}); node will have no parent now.`
          );
          updatedNode.parentId = null;
          newParentId = null;
        }
      } else {
        console.warn("No oldParentId found; node has no parent now.");
        updatedNode.parentId = null;
        newParentId = null;
      }
    } else {
      // --> "disassociate": move node from a client to Securispot NA
      updatedNode.parentId = securispotNode.id;
      securispotNode.children.push(updatedNode);
      newParentId = securispotNode.id;
    }

    // 6) Build the API parameters for parent/client ID
    //    We'll assume updatedNode.originalParentId is the stable client ID (if your data model has it).
    //    Otherwise, you may need custom logic for referencing which client the node belongs to.
    let parentIdForApi: string | null = null;
    let clientIdForApi: string | null = null;

    // Using updatedNode.originalParentId or fallback
    // (You'll need to ensure this property exists on 'Node' if you rely on it.)
    const fallbackClientId = ""; // or some fallback logic

    if (isAssociateCommand) {
      // "associate" => pass AFTER toggle parent ID
      parentIdForApi = newParentId;
      clientIdForApi = updatedNode.originalParentId ?? fallbackClientId;
    } else {
      // "disassociate" => pass BEFORE toggle parent's ID
      parentIdForApi = oldParentId;
      clientIdForApi = updatedNode.originalParentId ?? fallbackClientId;
    }

    // Clean up any null => undefined for the function call
    const parentIdParam = parentIdForApi || undefined;
    const clientIdParam = clientIdForApi || "";

    // 7) Call the API
    const apiSuccess = await updateAssociationStatus(
      nodeId,
      isAssociateCommand, // => "associate" or "disassociate"
      clientIdParam,
      parentIdParam
    );

    // 8) If the API call succeeded, update local state
    if (apiSuccess) {
      updatedNode.isAssociated = isAssociateCommand;
      updatedNode.isDissociated = !isAssociateCommand;
      setData([...updatedData]);
    } else {
      // Optionally revert local changes or handle it
      console.error("Failed to update association status via API. No local data update performed.");
    }

  } catch (error) {
    console.error("Unexpected error while toggling node association:", error);
  }
};
