import config from "../../../../config";
import { postRequest } from "../../../../api/Request";

const NodeCommandURL = `${config.API_URL}/nodecommand`;

export const callNodeCommandApi = async (nodeInfo: { 
  nodeId: string; 
  clientid: string; 
  command: string; 
  data?: any 
}): Promise<boolean> => {
  const payload = {
    nodeId: nodeInfo.nodeId,
    clientId: nodeInfo.clientid, // Include clientuuid
    command: nodeInfo.command,
    ...nodeInfo.data, // Flatten additional data
  };

  console.log("Sending NodeCommand payload:", payload);

  try {
    const response = await postRequest(NodeCommandURL, payload);

    if (!response || typeof response.success !== "boolean") {
      console.warn("Invalid response structure:", response);
      return false;
    }

    if (response.success) {
      console.log("Node command executed successfully:", response);
      return true;
    } else {
      console.error("Backend reported an error:", response.message || "Unknown error");
      return false;
    }
  } catch (error) {
    console.error("Error while executing node command:", error);
    return false;
  }
};