// src/App.tsx

import React, { useState } from 'react';
import classes from './App.module.css';
import Header from './navigation/header/Header';
import Menu from './navigation/menu/Menu';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import Mediaspots from './admin/mediaspots/Mediaspots';
import DevicesMapping from './devicesmapping/DevicesMapping';
import RoutersProvisionning from './superadmin/provisionning/RoutersProvisionning';
import MediaspotsProvisionning from './superadmin/provisionning/MediaspotsProvisionning';
import SimProvisionning from './superadmin/provisionning/SimProvisionning';
import Home from './home/Home';
import SignUp from './SignUp/SignUp';
import GlobalViewComponent from './GlobalView/globalviewcomponent';

import { withAuth } from './hoc/withAuth';

const App = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  return (
    <div className={classes.App}>
      <BrowserRouter>
        <Header />
        <div className={classes.AppContent}>
          <Menu isCollapsed={isMenuCollapsed} toggleCollapse={handleMenuToggle} />
          <div
            className={`${classes.MainContent} ${
              isMenuCollapsed ? classes.MainContentCollapsed : classes.MainContentExpanded
            }`}
          >
            <ToastProvider>
              <Switch>
                <Redirect exact from="/supervision" to="/supervision/global" />
                <Redirect exact from="/admin" to="/admin/devices" />
                <Redirect exact from="/provisionning" to="/provisionning/mediaspots" />
                <Redirect exact from="/" to="/supervision" />
                <Redirect exact from="/signup" to="/signup/user" />

                <Route path="/supervision" render={() => <Home isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/supervision/global" render={() => <Home isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/supervision/helmet" render={() => <Home isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/supervision/area" render={() => <Home isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/supervision/proximity" render={() => <Home isMenuCollapsed={isMenuCollapsed} />}/>

                <Route path="/admin/mediaspots" render={() => <Mediaspots />} />
                <Route path="/admin/devices" render={() => <DevicesMapping />} />

                <Route path="/provisionning/routers" render={() => <RoutersProvisionning />} />
                <Route path="/provisionning/mediaspots" render={() => <MediaspotsProvisionning />} />
                <Route path="/provisionning/sim" render={() => <SimProvisionning />} />

                <Route path="/signup/user" render={() => <SignUp />} />

                <Route path="/globalview" render={() => <GlobalViewComponent />} />
              </Switch>
            </ToastProvider>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default withAuth(App);
