// filters.tsx
import i18n from '../../i18n';
import { FilterProps, Row } from 'react-table';
import { TableData, RowData } from './types'; // Ensure correct path to 'types.ts'

// A default UI for column filtering
export function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: FilterProps<TableData>) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`${i18n.t('search')}...`}
      style={{
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ddd',
      }}
    />
  );
}

// A UI for the global filter
interface GlobalFilterProps {
  preGlobalFilteredRows: Row<RowData>[]; // Update to use RowData
  globalFilter: string;
  setGlobalFilter: (filterValue: string) => void;
}

export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps) {
  const count = preGlobalFilteredRows.length;

  return (
    <span>
      Search:{' '}
      <input
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder={`${count} ${i18n.t('recordsfilterstable')}...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
}
