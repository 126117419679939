import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faBinoculars,
  faHardHat,
  faPersonFalling,
  faWarehouse,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import Tabs from "../navigation/tabs/Tabs";
import { Interval, TabItem } from "../navigation/tabs/TabsTypes";
import { AlarmsCountCard } from "../stats/AlarmsCountCard";
import { AlarmsList } from "../stats/AlarmsList";
import { AreaInStats } from "../stats/AreaInStats";
import { GlobalDashboardStats } from "../stats/GlobalDashboard";
import { HatWearingStats } from "../stats/HatWearingStats";
import { LastEventDateCard } from "../stats/LastEventDateCard";
import { LyingPersonStats } from "../stats/LyingPersonStats";
import { OverloadedStats } from "../stats/OverloadedStats";
import { CollisionStats } from "../stats/CollisionStats";
import classes from "./Home.module.css";
import { getClientSerials } from "../api/Auth";
import { subDays } from "date-fns";
import { findHost } from "../helpers/MappingSecurispot";
import i18n from "../i18n";
interface HomeProps {
  isMenuCollapsed: boolean;
}

interface HomeProps {
  isMenuCollapsed: boolean;
}

const tabItems: TabItem[] = [
  { label: i18n.t("GlobalTab"), path: "/supervision/global", icon: faBinoculars as IconDefinition },
  { label: i18n.t("AreaTab"), path: "/supervision/area", icon: faWarehouse as IconDefinition },
  { label: i18n.t("HelmetTab"), path: "/supervision/helmet", icon: faHardHat as IconDefinition },
  { label: i18n.t("LyingTab"), path: "/supervision/lying", icon: faPersonFalling as IconDefinition },
  { label: i18n.t("OverloadedTab"), path: "/supervision/overloaded", icon: faWarehouse as IconDefinition },
  { label: i18n.t("collisionTab"), path: "/supervision/collision", icon: faTruck as IconDefinition },
  { label: i18n.t("AlarmsTab"), path: "/supervision/alarms", icon: faBell as IconDefinition },
];

const Home: React.FC<HomeProps> = ({ isMenuCollapsed }) => {
  const now = Date.now();
  const oneDayAgo = subDays(now, 1).setHours(0, 0, 0, 0);

  const [startDatePeriodTimestamp, setStartDatePeriodTimestamp] = useState<number>(oneDayAgo);
  const [endDatePeriodTimestamp, setEndDatePeriodTimestamp] = useState<number>(now);
  const [selectedTab, setSelectedTab] = useState<TabItem | undefined>(tabItems[0]);
  const [serials, setSerials] = useState<string[]>([]);
  const [selectedSerials, setSelectedSerials] = useState<string[]>([]);
  const [serialRequest, setSerialRequest] = useState<string>("");
  const [title, setTitle] = useState<string>(i18n.t("AllSecurispots"));

  useEffect(() => {
    if (selectedSerials.length > 1) {
      setSerialRequest("");
      setTitle(i18n.t("AllSecurispots"));
    } else if (selectedSerials.length === 1) {
      setSerialRequest(selectedSerials[0]);
      setTitle(findHost(selectedSerials[0]));
    }
  }, [selectedSerials]);

  useEffect(() => {
    const userSerials = getClientSerials();
    if (userSerials) {
      setSerials(userSerials);
      setSelectedSerials(userSerials);
    }
  }, []);

  return (
    <>
      <Tabs
        interval={Interval.DAY}
        items={tabItems}
        onIntervalChanged={() => console.log("interval changed")}
        onPeriodChange={(dates) => {
          setStartDatePeriodTimestamp(dates[0]);
          setEndDatePeriodTimestamp(dates[1]);
        }}
        onSelect={setSelectedTab}
        selectedTab={selectedTab}
        startDatePeriodTimestamp={startDatePeriodTimestamp}
        endDatePeriodTimestamp={endDatePeriodTimestamp}
        serials={serials}
        selectSerials={setSelectedSerials}
        selectedSerials={selectedSerials}
        isMenuCollapsed={isMenuCollapsed}
      />

      <div className={classes.HomeContainer}>
        <Switch>
          <Route exact path="/supervision/alarms" render={() => <><LastEventDateCard /><AlarmsCountCard /><AlarmsList numberDays={4} title={title} /></>} />
          <Route exact path="/supervision/global" render={() => <GlobalDashboardStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
          <Route exact path="/supervision/helmet" render={() => <HatWearingStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
          <Route exact path="/supervision/overloaded" render={() => <OverloadedStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
          <Route exact path="/supervision/area" render={() => <AreaInStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
          <Route exact path="/supervision/lying" render={() => <LyingPersonStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
          <Route exact path="/supervision/collision" render={() => <CollisionStats startDate={startDatePeriodTimestamp} endDate={endDatePeriodTimestamp} serial={serialRequest} title={title} />} />
        </Switch>
      </div>
    </>
  );
};

export default Home;