import { Node } from '../types';
import { updateNodeNameById } from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';
import { findNodeByIdAndEnrich } from '../helpers';
interface HandleSaveEditParams {
  nodeId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  newNodeName: string;
  newlyAddedNodeId: string | null;
  handleCancelEdit: (nodeId?: string) => void;
  handleDeleteNode: (nodeId: string) => Promise<void>;
}

export const handleSaveEditLogic = async ({
  nodeId,
  data,
  setData,
  newNodeName,
  newlyAddedNodeId,
  handleCancelEdit,
  handleDeleteNode,
}: HandleSaveEditParams): Promise<void> => {
  try {
    // Validate and process the new node name
    if (newNodeName.trim() !== '') {
      // Find the node to get `clientuuid`
      const nodeToSave = findNodeByIdAndEnrich(data, nodeId);
      if (!nodeToSave || !nodeToSave.originalParentId) {
        throw new Error(`clientuuid (originalParentId) is missing for nodeId: ${nodeId}`);
      }

      // Step 1: Call the backend API to save the updated node name
      const success = await callNodeCommandApi({
        nodeId,
        clientid: nodeToSave.originalParentId, // Pass clientuuid
        command: 'save',
        data: { newName: newNodeName.trim() },
      });

      if (success) {
        // Step 2: Update local state with the new name
        const updatedData = updateNodeNameById(data, nodeId, newNodeName.trim());
        setData(updatedData);
      } else {
        console.error(`Failed to save node name for nodeId: ${nodeId}`);
      }
    } else if (newlyAddedNodeId === nodeId) {
      // If the name is empty and it's a new node, delete the node
      await handleDeleteNode(nodeId);
    }
  } catch (error) {
    console.error(`Error handling save edit logic for nodeId: ${nodeId}`, error);
  } finally {
    handleCancelEdit();
  }
};