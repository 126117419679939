// src/GlobalVue/Treeutils/helpers/api/callTableDataMapsApi.ts

import config from "../../../../config";
import { postRequest } from "../../../../api/Request";
import { NodeDetails } from "../../../Treeutils/helpers/types";
import { TableDataResponse } from "../types";
import i18n from "../../../../i18n";
// Define the API URL for fetching table data
const TableDataMapsApiURL = `${config.API_URL}/getTableDataMaps`;



// Function to call the backend API
export const callTableDataMapsApi = async (details: NodeDetails, nodeType:string): Promise<TableDataResponse[] | null> => {
  const payload = {
    message: "Fetch table data for node",
    lang:i18n.language,
    data: {
      nodeDetails: details,
      nodeType:nodeType
    },
  };

  try {
    console.log("callTableDataMapsApi payload :",payload)
    const response = await postRequest(TableDataMapsApiURL, payload);

    if (response.error) {
      console.error("Error:", response.message);
      return null;
    }

    if (response && response.data) {
      console.log("Received table data from server:", response.data);
      return response.data as TableDataResponse[];
    } else {
      console.warn("Invalid response:", response);
      return null;
    }
  } catch (error) {
    console.error("Error while fetching table data:", error);
    return null;
  }
};
