import { NodeDetails } from "../../Treeutils/helpers/types";

export function deriveNodeType(details: NodeDetails): string {
    const { node, parent, grandparent } = details;
  
    // 1. Check if the node is a 'securispot'
    if (node.id.startsWith('securispot-')) return 'securispot';
  
    const hasChildren = Array.isArray(node.children) && node.children.length > 0;
    const hasParent = !!parent && parent.id && parent.id !== '__REACT_ARBORIST_INTERNAL_ROOT__'; // Check if the parent exists
    const hasreactarboristparent = !!parent && parent.id && parent.id === '__REACT_ARBORIST_INTERNAL_ROOT__';
    const parentHasParent = !!grandparent; // Check if the grandparent exists
  
    // 2. If it has no children but has a parent and the parent has a grandparent
    if (!hasChildren && hasParent && parentHasParent) {
      console.log("leaf")
      return 'leaf'
    };
  
    // 3. If it has children and a valid parent hierarchy
    if (hasChildren && hasParent && parentHasParent) {
      console.log('bureau')
      return 'bureau';
    }
    // 4. If it only has a direct parent but no grandparent
    if (hasreactarboristparent && !parentHasParent)
    {
      console.log("client")
      return 'client';
    }
    // 5. Default to unknown
    console.log("unknown")
    return 'unknown';
  }