//Created Date: 2024-03-We
//Author: Lucas Barrez
//-----
//Last Modified: Wednesday, 3rd April 2024 12:22:53 am
//Modified By: Lucas Barrez
//-----
//HISTORY:

interface Device {
  hostname: string;
}

interface DeviceMap {
  [serialNumber: string]: string;
}

const deviceMap: DeviceMap = {
    "1420122041893": "SecuriSPOT-001",
    "1422822006400": "SecuriSPOT-002",
  "1421022081340": "SecuriSPOT-003",
  "1420122041723": "SecuriSPOT-004",
    "1420122041771": "SecuriSPOT-005",
    "1420424251886": "SecuriSPOT-007",
    "1420424251892": "SecuriSPOT-008",
    "1420424251745": "SecuriSPOT-009",
    "1420424251891": "SecuriSPOT-010",
    "1420424251887": "SecuriSPOT-012",
    "1422822005478": "fp-xavier-dev-01",
    "1421524216186":"fp-securispot-orin01"
};

export const findHost = (serial: string): string => {
  if (serial === undefined ){
    return "Tous les securispots"
  }
  const hostname = deviceMap[serial];
  if (hostname) {
    return hostname;
  } else{
    return serial.toString();
  }
};
