import { NodeDetails } from '../../Treeutils/helpers/types';
import { getTableDataForNode } from '../tableDataMapper';

// Function to handle double-click events on a tree node
export async function handleNodeDoubleClickHelper(
  details: NodeDetails,
  setSelectedNode: React.Dispatch<React.SetStateAction<NodeDetails | null>>,
  setTableConfig: React.Dispatch<React.SetStateAction<any[]>>,
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
) {
  try {
    setSelectedNode(details); // Set the selected node

    // Await the data
    const tableConfigs = await getTableDataForNode(details);

    if (tableConfigs.length > 1) {
      setCurrentTab(tableConfigs[0].tableType);
    }

    setTableConfig(tableConfigs);
  } catch (error) {
    console.error('Error in handleNodeDoubleClickHelper:', error);
  }
}