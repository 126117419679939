// src/navigation/menu/components/NodesComponents/HeaderNode.tsx

import React from 'react';
import ManagerIcon from '../../../../assets/img/smartview.png';
import { faAngleDoubleLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HeaderNode.module.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
interface HeaderNodeProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

export const HeaderNode: React.FC<HeaderNodeProps> = ({ isCollapsed, toggleCollapse }) => (
  <div className={styles.HeaderContainer}>
    <div className={styles.MenuHeader}>
      {!isCollapsed && (
        <img
          className={styles.HeaderLogo}
          alt="SecuriSpot"
          src={ManagerIcon}
        />
      )}
      <button
        className={`${styles.CollapseButton} ${isCollapsed ? styles.CollapseButtonCollapsed : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          toggleCollapse();
        }}
      >
        <FontAwesomeIcon
          icon={isCollapsed ? faBars as IconProp : faAngleDoubleLeft as IconProp}
          className={`${styles.CollapseIcon} ${isCollapsed ? styles.RotateIcon : ''}`}
        />
      </button>
    </div>
  </div>
);
