// src/GlobalVue/Treeutils/helpers/helpers.ts

import { Node } from "./types";
import { callNodeCommandApi } from "./api/callNodeCommandApi";


/**
 * Removes nodes with specified IDs from the tree data.
 * @param nodes - The tree nodes.
 * @param ids - The IDs of the nodes to remove.
 * @returns The updated tree nodes.
 */
export const removeNodesById = (nodes: Node[], ids: string[]): Node[] => {
  return nodes
    .filter((node) => !ids.includes(node.id))
    .map((node) => ({
      ...node,
      children: node.children ? removeNodesById(node.children, ids) : undefined,
    }));
};

/**
 * Finds a node by its ID in the tree data, then enriches it with:
 *   - `parentId` (the immediate parent's id)
 *   - `originalParentId` (the root node's id)
 * @param nodes - The tree data.
 * @param id - The ID of the node to find.
 * @returns A new Node with parentId & originalParentId set, or null if not found.
 */
export function findNodeByIdAndEnrich(nodes: Node[], id: string): Node | null {
  // 1. Basic find
  const node = findNodeById(nodes, id);
  if (!node) return null; // not found in the tree

  // 2. Immediate parent
  const parent = findParentNode(nodes, node.id);

  // 3. Root node
  const root = findRootNode(nodes, node);

  // 4. Return a new Node object with updated fields
  return {
    ...node,
    parentId: parent ? parent.id : null,
    originalParentId: root ? root.id : null,
  };
}
/**
 * Finds a node by its ID in the tree data.
 * @param nodes - The tree nodes.
 * @param id - The ID of the node to find.
 * @returns The node if found, otherwise null.
 */
export const findNodeById = (nodes: Node[], id: string): Node | null => {
  for (const node of nodes) {
    if (node.id === id) return node;
    if (node.children) {
      const childNode = findNodeById(node.children, id);
      if (childNode) return childNode;
    }
  }
  return null;
};

/**
 * Updates the name of a node by its ID.
 * @param nodes - The tree nodes.
 * @param id - The ID of the node to update.
 * @param name - The new name for the node.
 * @returns The updated tree nodes.
 */
export const updateNodeNameById = (
  nodes: Node[],
  id: string,
  name: string
): Node[] => {
  return nodes.map((node) => {
    if (node.id === id) {
      return { ...node, name };
    }
    if (node.children) {
      return { ...node, children: updateNodeNameById(node.children, id, name) };
    }
    return node;
  });
};

/**
 * Adds a child node to a parent node by the parent's ID.
 * @param nodes - The tree nodes.
 * @param parentId - The ID of the parent node. If null, adds as a root node.
 * @param newNode - The new node to add.
 * @returns The updated tree nodes.
 */
export const addChildNodeById = (
  nodes: Node[],
  parentId: string | null,
  newNode: Node
): Node[] => {
  // Handle adding as a root-level node when parentId is null
  if (parentId === null) {
    console.log(`Adding node as a root-level node:`, newNode);
    return [...nodes, newNode];
  }

  // Traverse the tree to find the parent and add the child
  return nodes.map((node) => {
    if (node.id === parentId) {
      console.log(`Adding child node ${newNode.id} to parent node ${parentId}`);
      return {
        ...node,
        children: node.children ? [...node.children, newNode] : [newNode],
      };
    }

    if (node.children) {
      const updatedChildren = addChildNodeById(node.children, parentId, newNode);

      // If children are updated, return the updated node
      if (updatedChildren !== node.children) {
        return {
          ...node,
          children: updatedChildren,
        };
      }
    }

    // Return node unchanged if no match found
    return node;
  });
};

export function removeNodeAndGetParentId(
  nodes: Node[],
  nodeId: string
): { updatedData: Node[]; parentId: string | null } {
  let parentId: string | null = null;

  function recursiveRemove(nodes: Node[], parent: Node | null): Node[] {
    return nodes.filter((node) => {
      if (node.id === nodeId) {
        parentId = parent ? parent.id : null;
        return false; // Remove this node
      } else if (node.children) {
        node.children = recursiveRemove(node.children, node);
      }
      return true;
    });
  }

  const updatedData = recursiveRemove(nodes, null);
  return { updatedData, parentId };
}

/**
 * Function to check if a node is a Securispot device and prevent adding children
 * @param parentNode The parent node being checked
 * @returns boolean - Returns true if adding is prevented, false otherwise
 */
export const preventAddToSecurispot = (parentNode: Node | null): boolean => {
  if (parentNode) {
    const isParentSecurispot = parentNode.id.includes('securispot');
    
    // Check if the parent node is a Securispot and doesn't have children
    if (isParentSecurispot && parentNode.children && parentNode.children.length === 0) {
      console.warn("Cannot add child nodes to a Securispot device.");
      return true;  // Prevent the operation
    }
  }
  return false;  // Allow the operation
};


// Collect dragged nodes from the data
export const collectDraggedNodes = (nodes: Node[], ids: string[]): Node[] => {
  const draggedNodes = ids
    .map((id) => findNodeById(nodes, id)) // Node | undefined
    .filter((node): node is Node => node !== undefined); // Filter out undefined
  return draggedNodes;
};

// Insert dragged nodes into the new parent or root
export const insertDraggedNodes = (
  data: Node[], 
  draggedNodes: Node[], 
  parentId: string | null, 
  index: number
): Node[] => {
  if (parentId === null || parentId === undefined) {
    // Insert at the root level immutably
    return [...data.slice(0, index), ...draggedNodes, ...data.slice(index)];
  } else {
    return data.map((node) => {
      if (node.id === parentId) {
        const updatedChildren = node.children
          ? [...node.children.slice(0, index), ...draggedNodes, ...node.children.slice(index)]
          : [...draggedNodes];
        return { ...node, children: updatedChildren };
      }
      if (node.children) {
        return { ...node, children: insertDraggedNodes(node.children, draggedNodes, parentId, index) };
      }
      return node;
    });
  }
};

// Function to update the association and dissociation status of nodes
export const updateNodeAssociations = async (
  draggedNodes: Node[], 
  parentId: string | null, 
  data: Node[],
): Promise<void> => {
  for (const node of draggedNodes) {
    try {
      // Find the new parent node in the tree
      const newParentNode = parentId ? findNodeById(data, parentId) : null;
      
      // Determine the new association status
      const isDissociated = newParentNode?.name === 'Securispot NA'; // Dissociated if under "Securispot NA"
      // Ensure clientid is valid
      const clientid = node.originalParentId ?? ''; // Default to an empty string if null/undefined
      
      if (!clientid) {
        console.error(`Missing clientid for node ${node.id}. Skipping update.`);
        continue; // Skip this node if clientid is invalid
      }

      // Update the local state only if the API call succeeds
      node.isAssociated = !isDissociated;
      node.isDissociated = isDissociated;

      console.log(`Successfully updated association for node ${node.id} under ${newParentNode?.name || 'root'}`);
    } catch (error) {
      console.error(`Failed to update association status for node ${node.id}:`, error);
      // Add retry or notification logic here if needed
    }
  }
};



// Helper function to find the parent of a node
export function findParentNode(data: Node[], nodeId: string): Node | null {
  for (const node of data) {
    if (node.children && node.children.some((child) => child.id === nodeId)) {
      return node; // Found the parent node
    }
    // Recursively check the children
    if (node.children) {
      const parent = findParentNode(node.children, nodeId);
      if (parent) return parent;
    }
  }
  return null; // No parent found
}

// Helper function to find the root node (company or client node)
export function findRootNode(data: Node[], node: Node): Node | null {
  // Start with the given node as the current node.
  let currentNode: Node = node;
  let parentNode = findParentNode(data, currentNode.id);

  // Traverse up the tree while there is a parent.
  while (parentNode) {
    // Check if the parent's id indicates the "React" node.
    // You can adjust this condition (e.g., .startsWith("React") or .includes("React"))
    if (parentNode.id.startsWith("React")) {
      // The current node is the direct child of a "React..." node.
      return currentNode;
    }
    // Move upward
    currentNode = parentNode;
    parentNode = findParentNode(data, currentNode.id);
  }
  return currentNode;
}

// Helper function to get company name from the node
export function getCompanyNameFromNode(node: Node): string {
  // Assuming you store the company name as 'name' in each company node
  return node.name;
}

// Helper function to find the path of a node from the root to its parent
export const getNodePath = (nodeId: string, data: Node[]): { path: string, nodeId: string } | null => {
  const path: string[] = [];
  let currentNode = findNodeById(data, nodeId); // Assuming findNodeById is already defined

  if (!currentNode) {
    return null; // If the node is not found, return null
  }

  // Traverse upwards to collect the path, starting from the current node's parent
  let parentNode = findParentNode(data, nodeId);
  
  while (parentNode) {
    path.unshift(parentNode.id); // Add parent node ID to the beginning of the path
    parentNode = findParentNode(data, parentNode.id); // Traverse upwards to find the next parent
  }

  // Join the path array into a string separated by "|"
  const pathString = path.join('|');

  return {
    path: pathString,
    nodeId: currentNode.id,
  };
};


export const findOrCreateSecurispotNode = (updatedData: Node[]): Node => {
  let securispotNode = updatedData.find((n) => n.name === 'Securispot NA');

  if (!securispotNode) {
    securispotNode = {
      id: 'securispot-na',
      name: 'Securispot NA',
      children: [], // Initialize children as an empty array
    };
    return { ...securispotNode, children: [] };
  }

  // If the node exists but has no children, initialize them immutably
  if (!securispotNode.children) {
    securispotNode = { ...securispotNode, children: [] };
  }

  console.log('Securispot NA node:', securispotNode);
  return securispotNode;
};
/**
 * Helper to update the association status via API and handle errors
 */
export const updateAssociationStatus = async (
  nodeId: string, 
  isAssociated: boolean, 
  clientid: string,
  parentId?: string | null
): Promise<boolean> => {
  try {
    // `true` => associate, `false` => disassociate
    const command = isAssociated ? 'associate' : 'disassociate';

    const payload = {
      nodeId,
      clientid,
      command,
      ...(parentId ? { data: { parentId } } : {}),
    };
    
    // Call your backend
    const apiSuccess = await callNodeCommandApi(payload);

    if (!apiSuccess) {
      console.error(`Failed to update association status via API for node ${nodeId}`);
    } else {
      console.log(
        `Successfully updated association status for node ${nodeId}: ${
          isAssociated ? 'Associated' : 'Dissociated'
        }${parentId ? ` under parent ${parentId}` : ''}`
      );
    }
    return apiSuccess;
  } catch (error) {
    console.error(`Error while updating association status for node ${nodeId}:`, error);
    return false;
  }
};