import { Node } from '../types';
import {
  removeNodesById,
  findNodeByIdAndEnrich,
  preventAddToSecurispot,
  collectDraggedNodes,
  updateNodeAssociations,
  insertDraggedNodes,
  findRootNode,
  getNodePath
} from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';

export const handleMove = async (
  params: { dragIds: string[]; parentId: string | null; index: number },
  data: Node[],
  setData: React.Dispatch<React.SetStateAction<Node[]>>,
  showModal: (props: {
    nodeName: string;
    fromCompany: string;
    toCompany: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => Promise<boolean>
): Promise<boolean> => {
  const { dragIds, parentId, index } = params;

  // 1. Collect the dragged nodes
  const draggedNodes = collectDraggedNodes(data, dragIds);
  if (!draggedNodes.length) {
    console.error('No nodes found to drag');
    return false;
  }

  const draggedNode = draggedNodes[0];

  // 2. Find the current root node
  const currentRootNode = findRootNode(data, draggedNode);

  // 3. Find the new parent node and its root
  const newParentNode = parentId ? findNodeByIdAndEnrich(data, parentId) : null;
  const newRootNode = newParentNode ? findRootNode(data, newParentNode) : null;

  // 4. Confirm cross-root moves
  if (currentRootNode?.id !== newRootNode?.id) {
    const companyChangeConfirmed = await showModal({
      nodeName: draggedNode.name,
      fromCompany: currentRootNode?.name || '',
      toCompany: newRootNode?.name || '',
      onConfirm: () => Promise.resolve(true),
      onCancel: () => Promise.resolve(false),
    });

    if (!companyChangeConfirmed) {
      console.warn('Cross-root move not confirmed');
      return false;
    }
  }

  // 5. Prevent adding to "Securispot NA" if necessary
  if (preventAddToSecurispot(newParentNode)) {
    console.warn('Adding to Securispot NA is not allowed');
    return false;
  }

  // 6. Remove dragged nodes immutably
  const newData = removeNodesById([...data], dragIds);

  // **6.5** Handle "Securispot NA" specific logic and root crossing
  if (currentRootNode?.id !== newRootNode?.id) {
    // Update originalParentId only if crossing root nodes
    draggedNodes.forEach((node) => {
      node.originalParentId = newRootNode?.id || '';
        });
  }

  // 7. Update node associations immutably
  await updateNodeAssociations(draggedNodes, parentId, [...newData]);

  // 8. Insert dragged nodes immutably
  const updatedData = insertDraggedNodes([...newData], draggedNodes, parentId, index);

  // 9. Update backend with move command
  const nodePathInfo = getNodePath(draggedNodes[0].id, data);
  if (nodePathInfo) {
    const { path, nodeId } = nodePathInfo;
    const clientuuid = draggedNode.originalParentId;
    if (!clientuuid) {
      console.error('Missing clientuuid (originalParentId) for node:', draggedNode.id);
      return false;
    }
    
    const success = await callNodeCommandApi({
      nodeId,
      clientid: clientuuid,
      command: 'move',
      data: { path, newParentId: parentId },
    });
    
    if (!success) {
      console.error('Node move command failed');
      setData(data); // Revert to original state if API call fails
      return false;
    }
  } else {
    console.error('Node not found in the tree');
    return false;
  }

  // 10. Update state immutably
  setData([...updatedData]);
  return true;
};
